import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { useQuery } from "@tanstack/react-query";
import { get, find, isEmpty, random, isEqual } from "lodash";

import AppButton from "../../../../components/common/buttons/app-button";
import useGAEvent from "../../../../hooks/useGAEvent";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { useLiveVariant } from "../../../../context/LiveVariantContext";
import { getPlayableProperties } from "../../../../services/PropertyService";
import { validateImageURL } from "../../../../helpers/helpers";

/**
 * Modal for selecting an archive date to play past daily games.
 * @param {Object} props - Component props.
 * @param {number} props.score - The current user's score.
 * @param {Object} props.version - Version information of the game.
 * @param {string} props.visitorStatus - Status of the visitor (e.g., new, repeat).
 * @param {function} props.resetGameState - Resets various game state.
 */
export default function ArchiveSelectModal({ score, version, visitorStatus, resetGameState }) {
    const { sendEvent } = useGAEvent();
    const navigate = useNavigate();
    const { liveVariantState, setLiveVariantState } = useLiveVariant();
    const [playableProperties, setPlayableProperties] = useState([]);
    const [selected, setSelected] = useState(new Date(liveVariantState?.property_play_date));
    const [allowedDates, setAllowedDates] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [archivedProperties, setArchivedProperties] = useLocalStorage("archived-properties", {
        playableProperties: [],
        filteredPlayableProperties: [],
    });

    // Query to fetch playable properties
    useQuery({
        queryKey: ["playable-properties"],
        queryFn: getPlayableProperties,
        cacheTime: 0,
        onSuccess: async (data) => {
            const chicagoTimeZone = "America/Chicago";
            const currentDate = moment.tz(new Date(), chicagoTimeZone).format("YYYY-MM-DD");
            let properties = get(data, "data.properties", []);

            if (isEqual(properties, archivedProperties.playableProperties)) {
                const dates = archivedProperties.filteredPlayableProperties.map(
                    (property) => new Date(property.play_date)
                );
                setAllowedDates(dates);
                setPlayableProperties(archivedProperties.filteredPlayableProperties);
                return;
            }

            const filteredProperties = [];

            setIsProcessing(true);

            // Process the new properties
            for (let i = 0; i < properties.length; i++) {
                const property = properties[i];
                const playDate = moment.tz(property.play_date, chicagoTimeZone).format("YYYY-MM-DD");
                if (playDate > currentDate) continue; // Skip future properties

                const imageUrls = Array.isArray(property.Property?.image_urls)
                    ? property.Property.image_urls
                    : [];
                if (imageUrls.length === 0) continue; // Skip properties with no images

                const imageChecks = await Promise.all(imageUrls.map(validateImageURL));
                if (!imageChecks.every((img) => img.isValid)) continue; // Skip properties with invalid images

                filteredProperties.push(property); // Add valid property to the list

                // Update progress
                setProgress(Math.round(((i + 1) / properties.length) * 100));
            }

            // Set state only if new properties exist
            if (filteredProperties.length > 0) {
                const dates = filteredProperties.map((property) => new Date(property.play_date));
                setAllowedDates(dates);
                setPlayableProperties(filteredProperties);
                setArchivedProperties({
                    playableProperties: properties,
                    filteredPlayableProperties: filteredProperties,
                });
            }

            setIsProcessing(false);
        },
    });

    // Synchronize selected state with liveVariantState when the modal is shown
    useEffect(() => {
        if (liveVariantState?.property_play_date) {
            setSelected(new Date(liveVariantState.property_play_date));
        }
    }, [liveVariantState]);

    /**
     * Closes the modal.
     */
    const handleCloseModal = () => {
        setLiveVariantState({
            ...liveVariantState,
            show_archive_select_modal: false,
            show_date_picker_tooltip: false,
        });
    };

    /**
     * Handles date selection from the date picker.
     * @param {Date} date - The selected date.
     */
    const handleSelect = (date) => {
        const playDate = moment(date).format("YYYY-MM-DD");

        sendEvent("archive_select", {
            userScore: score,
            liveVariantVersion: version?.gaVersion,
            visitorStatus,
            archiveDate: playDate,
        });

        setSelected(date);

        const selectedProperty = find(playableProperties, ["play_date", playDate]);
        if (selectedProperty) {
            handleCloseModal();
            resetGameState();
            navigate(`/puzzle${selectedProperty.long_link}`);
        }
    };

    /**
     * Selects a random date from the allowed dates excluding today's date (Chicago timezone) and the selected date.
     */
    const handleRandom = () => {
        if (!isEmpty(allowedDates)) {
            // Get current date in Chicago timezone
            const todayChicago = moment.tz("America/Chicago").startOf("day");

            // Filter out current date and the selected date from allowedDates
            const filteredDates = allowedDates.filter((date) => {
                const allowedDate = moment(date).startOf("day");
                return !allowedDate.isSame(todayChicago, "day") && !allowedDate.isSame(selected, "day");
            });

            if (filteredDates.length > 0) {
                // Select a random date from the filtered dates
                const randomIndex = random(0, filteredDates.length - 1);
                handleSelect(filteredDates[randomIndex]);
            }
        }
    };

    return (
        <Modal
            centered
            backdrop="static"
            data-cy="live-variant-daily-score-modal"
            keyboard={false}
            show={liveVariantState?.show_archive_select_modal}
            onHide={handleCloseModal}
            className="select-location-modal"
        >
            <div className="tw-flex tw-justify-end tw-pt-2 tw-px-2 tw-cursor-pointer">
                <X size={30} onClick={handleCloseModal} />
            </div>
            <Modal.Body className="tw-text-center tw-flex tw-flex-col tw-items-center tw-gap-4 !tw-pt-0">
                <div>
                    <h5 className="app-text-title tw-mb-1">PriceMe Archive</h5>
                    <p className="tw-text-base tw-text-[#666666] tw-mb-0">Play daily games since Nov 2024</p>
                </div>
                {isProcessing ? (
                    <div className="tw-flex tw-flex-col tw-items-center tw-space-y-1">
                        <p className="tw-text-gray-600 !tw-text-xs tw-mb-1">
                            Checking archived properties... {progress}%
                        </p>
                        <progress
                            className="tw-w-full tw-max-w-xs tw-h-1 tw-bg-gray-200 tw-rounded-lg"
                            value={progress}
                            max="100"
                        ></progress>
                    </div>
                ) : (
                    <DatePicker
                        popperClassName="date-picker-popper"
                        wrapperClassName="date-picker-wrapper"
                        onSelect={handleSelect}
                        selected={selected}
                        autoFocus
                        includeDates={allowedDates}
                        inline
                    />
                )}

                <div className="tw-text-center tw-flex tw-flex-col tw-items-center">
                    <AppButton
                        variant="solid"
                        theme="gold"
                        className="purple-solid modal-button !tw-w-[200px] !tw-mt-0"
                        onClick={handleRandom}
                        isDisabled={isEmpty(playableProperties)}
                    >
                        Choose Random
                    </AppButton>
                </div>
            </Modal.Body>
        </Modal>
    );
}

ArchiveSelectModal.propTypes = {
    score: PropTypes.number.isRequired,
    version: PropTypes.shape({
        gaVersion: PropTypes.string,
    }).isRequired,
    visitorStatus: PropTypes.string.isRequired,
    resetGameState: PropTypes.func.isRequired,
};
