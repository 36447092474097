import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { syncUser } from "../../../services/UserService";
import { useUser } from "../../../context/UserContext";
import SelectLocationIcon from "../../../assets/icons-v2/select-location-icon.png";
import "./select-location-modal.scss";
import AppButton from "../../common/buttons/app-button";
import useModalState from "../../../hooks/useModalState";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { X } from "react-bootstrap-icons";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import config from "../../../config";
import toast from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";
import { find, isEmpty, isNil, debounce } from "lodash";
import { TOAST_MESSAGES } from "../../../lang/toast-messages.lang";
import GoogleAutoComplete from "../../common/google-auto-complete/google-auto-complete";
import { hasSelectLocationTimestampToday } from "../../../helpers/helpers";
import useGAEvent from "../../../hooks/useGAEvent";
import clsx from "clsx";

// Initial state area values
const INIT_AREA_VALUES = {
    areaName: "",
    zipCode: "",
    coordinates: {},
};

export default function SelectLocationModal() {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const { sendEvent } = useGAEvent();
    const { setModalId, isOpen } = useModalState();
    const { userState, setUserState } = useUser();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [areaValues, setAreaValues] = useState(INIT_AREA_VALUES);
    const [suggestedAreas, setSuggestedAreas] = useState([]);
    const { placePredictions, getPlacePredictions } = usePlacesService({
        apiKey: config.GOOGLE_MAPS_API_KEY,
        options: {
            componentRestrictions: { country: ["us"] }, // US only results
        },
    });

    // Debounce the getPlacePredictions function
    const debouncedGetPlacePredictions = useMemo(
        () =>
            debounce((input) => {
                if (areaValues?.areaName && areaValues.areaName.length) {
                    getPlacePredictions({ input, fields: ["name", "geometry"] });
                }
            }, 300),
        [placePredictions] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Handles the closing of the select location modal.
     */
    const handleSelectLocationModalClose = () => {
        setModalId("");
    };

    // Set suggested areas
    useEffect(() => {
        // Filter place prediction results from Chicago and IL only
        const filteredPlacePredictions = placePredictions.filter(
            (place) => find(place?.terms, (term) => term.value === "Chicago" || term.value === "IL") ?? false
        );

        if (filteredPlacePredictions?.length) {
            setSuggestedAreas(filteredPlacePredictions);
        }
    }, [placePredictions]);

    // One-time set initial places
    useEffect(() => {
        if (!isEmpty(userState?.current_location)) {
            getPlacePredictions({ input: userState.current_location, fields: ["name", "geometry"] });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Handles area request submission
    const handleSubmitRequest = async (e, onEnterPressedValue) => {
        try {
            if (!onEnterPressedValue) {
                e.preventDefault();
            }

            const userLocation = onEnterPressedValue || areaValues.areaName;

            setIsSubmitting(true);

            const updatedUserData = {
                ...userState,
                current_location: userLocation,
            };

            await syncUser(updatedUserData);

            toast.success("Location saved!", { duration: 1000 });

            sendEvent("area_select_lets_go", {
                isAuthenticated,
                userLocation,
            });

            setIsSubmitting(false);

            setUserState(updatedUserData, false);

            // Set the selected location timestamp after submit success
            localStorage.setItem("select_location_timestamp", moment().tz("America/Chicago").format());

            // Remove the previous properties in the local storage if location is updated
            if (userState.current_location !== areaValues.areaName) {
                localStorage.removeItem("previousProperties");
            }

            handleSelectLocationModalClose();

            if (hasSelectLocationTimestampToday()) {
                setTimeout(() => {
                    if (window.location.pathname === "/daily-properties") {
                        window.location.reload();
                    } else {
                        navigate("/daily-properties");
                    }
                }, 1000);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message, { id: "LOCATION.INVALID" });
            } else {
                toast.error(TOAST_MESSAGES.LOCATION.INVALID, { id: "LOCATION.INVALID" });
            }
            setIsSubmitting(false);
        }
    };

    // Resets form state on modal close
    useEffect(() => {
        if (!isOpen("SELECT_LOCATION_MODAL")) {
            setAreaValues(INIT_AREA_VALUES);
            setSuggestedAreas([]);
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isNil(userState.current_location) && !isEmpty(userState.current_location)) {
            setAreaValues((prev) => ({ ...prev, areaName: userState.current_location }));
            setIsDisabled(false);
        }
    }, [userState.current_location]);

    return (
        <Modal
            centered
            backdrop="static"
            data-cy="select-location-modal"
            keyboard={false}
            show={isOpen("SELECT_LOCATION_MODAL")}
            onHide={() => handleSelectLocationModalClose()}
            className={clsx("select-location-modal", !isAuthenticated && "guest-modal")}
        >
            <div className="close-icons">
                <X className="close" size={30} onClick={() => handleSelectLocationModalClose()} />
            </div>
            <Modal.Body className="pt-0 pb-0 px-4">
                <div className="select-location-modal-slide-group">
                    <div className="slide-img-wrapper">
                        <img
                            className="select-location-modal-icon house-sold-icon"
                            alt="house-sold-icon"
                            src={SelectLocationIcon}
                        />
                    </div>
                    <h5 className="app-text-title text-center pb-2">Where do you want to play?</h5>
                    <p>Choose a location to get started</p>

                    <form id="select-location-form" className="request-form" onSubmit={handleSubmitRequest}>
                        <GoogleAutoComplete
                            wrapperClassName="mb-4 w-100 mx-auto form-control"
                            placeholder="Enter an address, neighborhood or city"
                            onChange={(e) => {
                                setIsDisabled(true);
                                debouncedGetPlacePredictions(e.target.value);
                                if (isEmpty(e.target.value)) {
                                    setSuggestedAreas([]);
                                }
                                setAreaValues((prev) => ({
                                    ...prev,
                                    areaName: e.target.value,
                                }));
                            }}
                            inputValue={areaValues.areaName}
                            suggestedAreas={areaValues.areaName.trim().length ? suggestedAreas : []}
                            onSelect={(sa, isEnterPressed) => {
                                if (sa) {
                                    setAreaValues((prev) => ({
                                        ...prev,
                                        areaName: sa.description,
                                    }));

                                    if (isEnterPressed) {
                                        handleSubmitRequest({}, sa.description);
                                    }
                                }
                                setIsDisabled(false);
                            }}
                            isLoading={isSubmitting}
                        />
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex pt-0 flex-column gap-3">
                <AppButton
                    isDisabled={isSubmitting || isDisabled}
                    theme="purple"
                    className="w-50 mb-2 submit-req-btn"
                    type="submit"
                    onClick={handleSubmitRequest}
                >
                    {!isSubmitting ? "Let's go" : <ClipLoader size={15} />}
                </AppButton>
            </Modal.Footer>
        </Modal>
    );
}
