import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./circle-loading-progress.scss"; // Import CSS for centering styles
import PriceMeLogo from "../../../assets/icons-v2/priceme-logo.svg";
import CustomSvg from "../custom-svg/custom-svg";
import { find, random } from "lodash";
import { motion } from "framer-motion";
import { useAuth0 } from "@auth0/auth0-react";

const SLOW_INTERVAL = 2000;

let firstItemProps = {
    min: 0,
    max: 49,
    interval: 1500,
};

let secondItemProps = {
    min: 50,
    max: 100,
    interval: 1000,
};

const LOGGED_IN_LOADING_STATES = [
    {
        text: "Finding properties nearby...",
        ...firstItemProps,
    },
    {
        text: "Choosing most recent sales...",
        ...secondItemProps,
    },
];

const LOGGED_OUT_LOADING_STATES = [
    {
        text: "Loading new user practice round...",
        ...firstItemProps,
    },
    {
        text: "Turning on hints...",
        ...secondItemProps,
    },
];

const CircleLoadingProgress = ({ isLoading, finishLoading }) => {
    const { isAuthenticated } = useAuth0();
    const [time, setTime] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const LOADING_STATES = isAuthenticated ? LOGGED_IN_LOADING_STATES : LOGGED_OUT_LOADING_STATES;
    const [loadingText, setLoadingText] = useState(LOADING_STATES[0].text);
    const [assetsLoaded, setAssetsLoaded] = useState(false);

    useEffect(() => {
        // Create a new EventSource
        const eventSource = new EventSource("/api/events", { withCredentials: true });

        // Event handler for incoming messages
        eventSource.addEventListener("UPDATE_LOADER_TITLE_TEXT", (e) => {
            const data = JSON.parse(e.data);

            LOGGED_IN_LOADING_STATES[1].text = data.titleText;
            LOGGED_IN_LOADING_STATES[1].interval = SLOW_INTERVAL;
        });

        // Event handler for errors
        eventSource.onerror = (error) => {
            console.error("SSE error:", error);
        };

        // Clean up EventSource when component unmounts
        return () => {
            eventSource.close();
        };
    }, []);

    useEffect(() => {
        const { text, interval } =
            find(
                LOADING_STATES,
                (loadingState) => percentage >= loadingState.min && percentage <= loadingState.max
            ) ?? LOADING_STATES[0];

        const timer = setInterval(() => {
            if (percentage < 100) {
                setLoadingText(text);

                setPercentage((prevPercentage) => {
                    // Set the default percentage increment to half (50%)
                    let percentageIncrement = 50;

                    // If the previous percentage is more than half, increment by small amounts
                    if (prevPercentage >= 50) {
                        percentageIncrement = random(1, 5);
                    }

                    // If the data has finished loading and 2.5s has passed, complete the percentage to 100%
                    if (!isLoading && time > 2500) {
                        percentageIncrement = 100 - prevPercentage;
                    }

                    return prevPercentage + percentageIncrement;
                });
            } else {
                clearInterval(timer);
                finishLoading();
            }

            setTime((prevTime) => prevTime + interval);
        }, interval);

        return () => clearInterval(timer);
    }, [percentage]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ensure that the assets are preloaded to avoid delays
    useEffect(() => {
        const img = new Image();
        img.src = PriceMeLogo;
        img.onload = () => setAssetsLoaded(true);
    }, []);

    // Ensure that loadingText and percentage have safe initial states that align with the first render.
    useEffect(() => {
        setLoadingText(LOADING_STATES[0].text);
        setPercentage(0);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!assetsLoaded) {
        return (
            <div className="tw-text-center tw-m-10">
                <div>Loading assets...</div>
            </div>
        );
    }

    return (
        <div className="loading-container">
            {loadingText ? (
                <>
                    <div className="loading-text">
                        <motion.div
                            key={loadingText}
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.25 }}
                        >
                            {loadingText}
                        </motion.div>
                    </div>

                    <div className="progressbar-container">
                        <CircularProgressbar
                            value={percentage}
                            styles={buildStyles({
                                rotation: 0,
                                strokeLinecap: "round",
                                pathTransitionDuration: 1,
                                pathColor: `rgba(252,148,90, 1)`,
                                trailColor: "#d6d6d6",
                                backgroundColor: "#3e98c7",
                            })}
                        />
                        <CustomSvg
                            src={PriceMeLogo}
                            size={{ width: 250, height: 250 }}
                            className="center-img"
                        />
                    </div>
                </>
            ) : (
                <div className="tw-text-center tw-m-10">
                    <div>Preparing Loader...</div>
                </div>
            )}
        </div>
    );
};

export default CircleLoadingProgress;
