import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import clsx from "clsx";
import { X } from "react-bootstrap-icons";
import { isMobile } from "react-device-detect";
import { RiExternalLinkLine } from "react-icons/ri";

import CustomButton from "../../../../components/common/buttons/custom-button/custom-button";
import SurrenderIcon from "../../../../assets/icons-v2/surrender-icon.png";

/**
 * FullListingModal displays a modal asking the user if they want to view the full listing or go back to guessing.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {boolean} props.show - Controls the visibility of the modal.
 * @param {Function} props.onClick - Function triggered by click event.
 * @returns {JSX.Element} The rendered modal component.
 */
export default function FullListingModal({ show, onClick }) {
    return (
        <Modal
            centered
            backdrop="static"
            data-cy="live-variant-show-full-listing-modal"
            keyboard={false}
            show={show}
            className="select-location-modal"
        >
            <div className="tw-flex tw-justify-end tw-pt-2 tw-px-2 tw-cursor-pointer">
                <X size={30} onClick={() => onClick("back_to_guess_price")} />
            </div>
            <Modal.Body
                className={clsx(
                    "tw-text-center tw-flex tw-flex-col tw-items-center tw-gap-4 tw-justify-center",
                    {
                        "!tw-px-[30px]": isMobile,
                    }
                )}
            >
                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                    <img className="tw-w-[70px] tw-mb-3 tw-mt-[-20px]" src={SurrenderIcon} alt="star-icon" />
                    <p className="!tw-text-[21px] !tw-font-bold !tw-font-merriweather">Give up?</p>
                    <p className="tw-text-base tw-text-[#666666]">
                        Are you sure you don’t want to guess the price?
                    </p>

                    <CustomButton
                        className="app-button purple-solid !tw-flex tw-justify-center !tw-items-center !tw-gap-1"
                        text="See the full listing"
                        rightIcon={<RiExternalLinkLine fontSize={20} />}
                        handleClick={() => onClick("see_full_listing_modal")}
                    />
                </div>

                <p
                    className="!tw-text-[#6C26C4] !tw-text-[14px] tw-underline tw-cursor-pointer hover:tw-text-[#501F91]"
                    onClick={() => onClick("back_to_guess_price")}
                >
                    Back to guess the price
                </p>
            </Modal.Body>
        </Modal>
    );
}

FullListingModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};
