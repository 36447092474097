import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { get, inRange, isEmpty } from "lodash";
import Sticky from "react-sticky-el";
import MoonLoader from "react-spinners/MoonLoader";

import PropertyInfo from "../../../../components/common/PropertyInfo";
import { getRandomProperty } from "../../../../services/UserService";
import useGAEvent from "../../../../hooks/useGAEvent";
import "./plus-minus5.scss";
import { IoChevronUp } from "react-icons/io5";
import { motion } from "framer-motion";
import TileRow from "./TileRow";
import PlusMinusResultModal from "../../../../components/modals/PlusMinusResultModal";
import { formatPrice } from "../../../../helpers/helpers";

const MAX_DIGIT = 8;
const MAX_GUESS = 6;
const INCORRECT_THRESHOLD = 2;

const GAME_STATES = Object.freeze({ ONGOING: "ONGOING", LOSE: "LOSE", WIN: "WIN" });

export default function PlusMinus5({ version }) {
    const { sendEvent } = useGAEvent();
    const bodyRef = useRef(null);
    const inputRef = useRef(null);
    const [property, setProperty] = useState(null);
    const [guesses, setGuesses] = useState(Array(MAX_GUESS).fill(null));
    const [currentIndex, setCurrentIndex] = useState(0);
    const [expand, setExpand] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [gameState, setGameState] = useState(GAME_STATES.ONGOING);
    const [ongoingAnimation, setOngoingAnimation] = useState("idle");

    const dailyProperties = useQuery({
        queryKey: ["plus-minus-random-property"],
        queryFn: getRandomProperty,
        enabled: true,
        cacheTime: 0,
        onSuccess: (data) => {
            const property = get(data, "data.property", null);
            if (property?.price) {
                setProperty(property);
                sendEvent("plus_minus_start", { plusMinusVersion: version });
            }
        },
    });

    const isLoading = dailyProperties?.isLoading || dailyProperties?.isFetching || !property;
    const actualPriceValue = property?.price;

    const correctGuessIndex = guesses.findIndex((guess) => +guess === actualPriceValue);

    const checkTileMode = useCallback(
        (rowIndex, tileIndex, tileValue) => {
            const actualPriceSplit = actualPriceValue
                ? actualPriceValue.toString().padStart(MAX_DIGIT, "0").split("")
                : [];

            if (rowIndex === currentIndex || isEmpty(tileValue)) {
                return "default";
            }

            const correctTileValue = +actualPriceSplit[tileIndex];

            if (correctTileValue === +tileValue) {
                return "correct";
            }

            const inThreshold = inRange(
                +tileValue,
                correctTileValue - INCORRECT_THRESHOLD,
                correctTileValue + INCORRECT_THRESHOLD + 1
            );

            return inThreshold ? "close" : "incorrect";
        },
        [currentIndex, actualPriceValue]
    );

    const handleChange = (event) => {
        if (ongoingAnimation !== "idle" || gameState !== GAME_STATES.ONGOING) {
            event.preventDefault();

            return;
        }

        const value = event.target.value;

        setGuesses((prevGuesses) => {
            const newGuesses = [...prevGuesses];
            newGuesses[currentIndex] = value;
            return newGuesses;
        });
    };

    const handleEnterKey = (event) => {
        if (ongoingAnimation !== "idle" || gameState !== GAME_STATES.ONGOING) {
            event.preventDefault();

            return;
        }

        const currentGuessLength = guesses[currentIndex]?.length ?? 0;

        if (event.code === "Enter") {
            event.preventDefault();

            if (currentGuessLength === MAX_DIGIT) {
                handleSubmit(event);
            }
        }

        if (currentGuessLength >= MAX_DIGIT && event.code !== "Backspace") {
            event.preventDefault();
            return;
        }
    };

    const handleSubmit = (event) => {
        const isCorrect = +guesses[currentIndex] === actualPriceValue;
        const noMoreGuess = currentIndex + 1 === guesses.length;

        sendEvent("plus_minus_guess", {
            plusMinusGuess: +guesses[currentIndex],
            plusMinusCorrect: isCorrect,
            plusMinusVersion: version,
        });

        // Set the ongoing animation to "transit" to prep before the next animation so that this is not "idle"
        setOngoingAnimation("transit");

        if (isCorrect) {
            setGameState(GAME_STATES.WIN);
        }

        if (noMoreGuess) {
            setGameState(GAME_STATES.LOSE);
        }

        setCurrentIndex((prevIndex) => prevIndex + 1);
        event.target.value = "";
    };

    useEffect(() => {
        // Show the modal if the game state has been updated from "ONGOING" and there's no ongoing animation ("idle")
        if (gameState !== GAME_STATES.ONGOING && ongoingAnimation === "idle") {
            setShowModal(true);
        }
    }, [gameState, ongoingAnimation]);

    if (isLoading) {
        return (
            <div className="tw-flex tw-items-center tw-justify-center tw-h-3/4">
                <MoonLoader size={40} color="#63c19f" />
            </div>
        );
    }

    // Calculate the guess input height when it is not expanded based on the current index
    // This means each of the TileRow is about 2.3rem
    const guessInputHeight = currentIndex * 2.3 + 6.5;

    return (
        <div className="property-container" ref={bodyRef}>
            <PropertyInfo property={property} />
            <Sticky
                className="guess-input-sticky"
                mode="bottom"
                positionRecheckInterval={expand ? 50 : 2}
                onClick={() => setExpand(true)}
            >
                <motion.div
                    className="guess-input-container overflow-hidden px-3 pt-0 pb-3 guess-input-container-shadow"
                    initial={{ height: `${guessInputHeight}rem` }}
                    animate={{
                        height: expand ? "18.5rem" : `${guessInputHeight}rem`,
                    }}
                    transition={{
                        height: { ease: "easeOut", duration: 0.5, delay: 0.1 },
                    }}
                >
                    <div className="flex-wrap d-flex align-items-center justify-content-center">
                        <motion.div
                            className="score-to-beat mw-100 px-4"
                            initial={false}
                            animate={{ rotate: expand ? 180 : 0 }}
                            transition={{ ease: "easeOut", duration: 0.5, delay: 0.1 }}
                        >
                            <IoChevronUp
                                className="hover:tw-cursor-pointer"
                                fontSize={25}
                                onClick={(e) => {
                                    // We need to stop the propagation to the Sticky component's onClick handler if expanded
                                    if (expand) {
                                        e.stopPropagation();
                                    }

                                    setExpand(!expand);
                                }}
                            />
                        </motion.div>
                    </div>
                    <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-center tw-gap-1">
                        <span className="tw-font-bold">Guess the price!</span>

                        {guesses.map((guess, index) => (
                            <TileRow
                                key={`tile-row-${index}`}
                                expand={expand}
                                length={MAX_DIGIT}
                                rowIndex={index}
                                value={guess}
                                gameIndex={currentIndex}
                                checkTileMode={checkTileMode}
                                inputRef={inputRef}
                                handleSubmit={handleSubmit}
                                setOngoingAnimation={setOngoingAnimation}
                            />
                        ))}

                        <input
                            type="number"
                            inputMode="numeric"
                            className="tw-opacity-0 tw-fixed tw--left-[2000px]"
                            ref={inputRef}
                            value={guesses[currentIndex] || ""}
                            maxLength={MAX_DIGIT}
                            onChange={handleChange}
                            onKeyDown={handleEnterKey}
                        />
                    </div>
                </motion.div>
            </Sticky>

            {showModal && (
                <PlusMinusResultModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    score={(correctGuessIndex !== -1 ? correctGuessIndex : guesses.length) + 1}
                    busted={correctGuessIndex === -1}
                    actualPrice={formatPrice(actualPriceValue)}
                    version={version}
                />
            )}
        </div>
    );
}

PlusMinus5.propTypes = {
    version: PropTypes.string.isRequired,
};
