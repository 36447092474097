import React from "react";
import "./sidemenu.scss";
import { SIDEMENU_ITEMS, LIVE_VARIANT_SIDEMENU_ITEMS } from "../../constants/sidemenu-items.constants";
import { filter, isNil } from "lodash";
import PropTypes from "prop-types";
import { useNonInitialEffect } from "../../../hooks/useNonInitialEffect";
import useLocalStorage from "../../../hooks/useLocalStorage";
import SideMenuItem from "./sidemenu-item/sidemenu-item";
import { useAuth0 } from "@auth0/auth0-react";
import "react-social-icons/facebook";
import "react-social-icons/instagram";
import CustomSvg from "../custom-svg/custom-svg";
import FbIcon from "../../../assets/icons-v2/facebook.png";
import Insta from "../../../assets/icons-v2/insta.png";
import SwitchGameIcon from "../../../assets/icons-v2/switch_game.png";
import clsx from "clsx";

import config from "../../../config";

const Sidemenu = (props) => {
    const { isAuthenticated } = useAuth0();
    const [preferredVersion] = useLocalStorage("live-variant-preferred-version");
    const isLiveVariant =
        (preferredVersion && preferredVersion !== "classic") || window.location.pathname === "/";

    const filteredMenuItems = filter(SIDEMENU_ITEMS, (item) =>
        !isNil(item.isRegistered) ? item.isRegistered === isAuthenticated : true
    );

    const liveVariantSideMenuItems = LIVE_VARIANT_SIDEMENU_ITEMS.map((item) => {
        let newItem = item;

        if (item.label === "Back to Daily Game") {
            newItem.url = `/${preferredVersion}`;
        }

        /* if (item.label === "Choose Area") {
            newItem.label = "Switch Area"
        } */

        return newItem;
    });

    const menuItems = isLiveVariant ? liveVariantSideMenuItems : filteredMenuItems;

    /**
     * Custom React hook to add an event listener to handle clicks outside the SideMenu component.
     * The event listener will be added only when the menu is open.
     */
    useNonInitialEffect(() => {
        // Add event listener to handle clicks outside the SideMenu component
        const handleOutsideClick = (event) => {
            const sideMenu = document.querySelector(".side-menu");
            const menuIcons = document.querySelector(".menu-icons");

            if (sideMenu && !sideMenu.contains(event.target) && !menuIcons.contains(event.target)) {
                props.onClose();
            }
        };

        if (props.isOpen) {
            document.addEventListener("click", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [props.isOpen, props.onClose]);

    return (
        <div className={clsx("side-menu", props.isOpen && "open")}>
            <ul className="menu-items">
                {menuItems.map((item, index) => {
                    if (
                        (window.location.pathname.includes("puzzle") && item.label === "Choose Area") ||
                        (!window.location.pathname.includes("puzzle") && item.label === "Play archives")
                    ) {
                        return null;
                    }

                    return (
                        <SideMenuItem
                            key={"menu-items" + index}
                            item={item}
                            onProfileToggle={props.onProfileToggle}
                            onClose={props.onClose}
                        />
                    );
                })}
                <hr className="tw-mx-4" />
                <li className="tw-flex tw-gap-2" onClick={() => window.open(config.BASE_URL, "_self")}>
                    <CustomSvg src={SwitchGameIcon} size={{ width: 27, height: 27 }} /> Switch Game
                </li>
            </ul>
            <div className="follow">
                <center>Follow Us</center>
            </div>
            <div className="social-media-links">
                <center>
                    <CustomSvg
                        src={FbIcon}
                        size={{ height: 35, width: 35 }}
                        onClick={() => window.open("https://www.facebook.com/groups/pricemeapp/", "_blank")}
                    />
                    <CustomSvg
                        src={Insta}
                        size={{ height: 35, width: 35 }}
                        onClick={() => window.open("https://www.instagram.com/pricemeapp/", "_blank")}
                    />
                </center>
            </div>
        </div>
    );
};

Sidemenu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Sidemenu;
