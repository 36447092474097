import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from "react-leaflet";
import { isMobile } from "react-device-detect";
import MiniMap from "../../../assets/icons-v2/map_icon.png";
import { getCoordinates } from "../../../services/PropertyService";
import "leaflet-fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css"; // Fullscreen CSS

/**
 * Component to set the map view on center or zoom change.
 * @param {Object} props - Props for the component.
 * @param {number[]} props.center - Map center coordinates.
 * @param {number} props.zoom - Zoom level.
 * @returns {null}
 */
const SetViewOnChange = ({ center, zoom }) => {
    const map = useMap();
    useEffect(() => {
        map.setView(center, zoom);
    }, [center, zoom, map]);
    return null;
};

/**
 * Initializes and stores the map instance in a ref.
 * @param {Object} props - Props for the component.
 * @param {Object} props.mapRef - Reference to store map instance.
 * @returns {null}
 */
const InitializeMap = ({ mapRef }) => {
    const map = useMap();
    useEffect(() => {
        mapRef.current = map;
    }, [map, mapRef]);
    return null;
};

/**
 * Handles fullscreen toggle events for the map.
 * Updates z-index and map opacity based on fullscreen state.
 *
 * @param {function} setZIndex - Callback to set the z-index value.
 * @param {function} setMapOpacity - Callback to set the map opacity.
 * @returns {null}
 */
const FullscreenHandler = ({ setZIndex, setMapOpacity }) => {
    useMapEvents({
        fullscreenchange: (e) => {
            const map = e.target;
            if (map.isFullscreen()) {
                setZIndex(1000); // Apply fullscreen z-index
                setMapOpacity(1); // Visible
            } else {
                setZIndex(1); // Default z-index
                setMapOpacity(0); // Hidden
            }
        },
    });
    return null;
};

const DEFAULT_ZOOM_LEVEL = 15;
const LIVE_VARIANT_DEFAULT_ZOOM_LEVEL = 7;

/**
 * CustomMap component displays a Leaflet map with fullscreen and overlay features.
 * @param {Object} props - Props for the component.
 * @param {Object} props.property - Property details containing location data.
 * @param {boolean} props.mapOverlayed - Flag for overlayed map display.
 * @returns {JSX.Element}
 */
const LeafletMap = ({ property, mapOverlayed }) => {
    const [center, setCenter] = useState([41.850033, -87.6500523]); // Initially centered at Chicago
    const [mapError, setMapError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [zIndex, setZIndex] = useState(1);
    const [mapOpacity, setMapOpacity] = useState(0);
    const mapRef = useRef();
    const isLiveVariant = ["over-under", "puzzle", "flip", "hidden-info"].includes(
        window.location.pathname.split("/")[1]
    );

    useEffect(() => {
        const fetchCoordinates = async () => {
            if (!property) return;

            if (property?.lat && property?.lng) {
                return setCenter([property.lat, property.lng]);
            }

            // For some reason, Openstreetmaps and other used geocoding API does not receive aprtment or unit number well
            // Removed apt/unit number (e.g., #1, Apt 2) and split address to get the main street, then trim any leading/trailing spaces
            const propertyAddress = property?.street_address
                ?.replace(/#\s?\d+\s?-\s?\d+/g, "")
                .split(",")[0]
                .trim();

            const address = [propertyAddress, property.city, property.state, property.zip_code].join(",");

            setLoading(true);
            try {
                const response = await getCoordinates(address);
                if (response?.lat && response?.lon) {
                    setCenter([response.lat, response.lon]);
                    setMapError(null);
                }
            } catch (error) {
                setMapError(error?.response?.data?.message || error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCoordinates();
    }, [property]);

    const handleReload = () => {
        setMapError(null); // Reset the error before retrying
        window.location.reload(true);
    };

    const handleMaximizeMap = () => {
        if (mapRef.current) {
            mapRef.current.toggleFullscreen();
        }
    };

    const renderError = () => (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-bg-gray-100 tw-p-6 tw-rounded-lg tw-shadow-lg tw-text-center">
            <p className="tw-text-lg tw-font-semibold tw-text-gray-700 tw-mb-2">
                Sorry, we couldn't load the map.
            </p>
            <p className="tw-text-sm tw-text-red-500 tw-mb-4">{mapError}</p>
            <button
                onClick={handleReload}
                className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-font-semibold tw-rounded hover:tw-bg-blue-600 tw-transition tw-duration-200"
            >
                Retry
            </button>
        </div>
    );

    const renderMapContainer = () => (
        <MapContainer
            center={center}
            zoom={isLiveVariant ? LIVE_VARIANT_DEFAULT_ZOOM_LEVEL : DEFAULT_ZOOM_LEVEL}
            className={clsx("tw-h-[167px]", mapOverlayed && "tw-h-[40px]")}
            zoomControl={false}
            attributionControl={false}
            fullscreenControl
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={center} />
            <SetViewOnChange
                center={center}
                zoom={isLiveVariant ? LIVE_VARIANT_DEFAULT_ZOOM_LEVEL : DEFAULT_ZOOM_LEVEL}
            />
            <InitializeMap mapRef={mapRef} />
            <FullscreenHandler setZIndex={setZIndex} setMapOpacity={setMapOpacity} />
        </MapContainer>
    );

    const renderMapContent = () => {
        if (mapOverlayed) {
            /**
             * When mapOverlayed is true:
             * - The map is initially hidden and only shown in fullscreen when toggled.
             * - No loading is needed, and the mini-map is hidden if an error occurs.
             */
            if (mapError) return null;

            return (
                <>
                    <img
                        src={MiniMap}
                        alt="mini-map"
                        onClick={handleMaximizeMap}
                        className="tw-cursor-pointer"
                    />
                    <div className={`tw-opacity-${mapOpacity} tw-absolute`}>{renderMapContainer()}</div>
                </>
            );
        }

        if (loading) {
            return (
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-bg-gray-100 tw-p-6 tw-rounded-lg tw-shadow-lg tw-text-center">
                    <p className="tw-text-lg tw-font-semibold tw-text-gray-700 tw-mb-2">Loading map...</p>
                </div>
            );
        }

        if (mapError) {
            return renderError();
        }

        return renderMapContainer();
    };

    return (
        <div
            className={clsx(
                `tw-h-[167px]`,
                mapOverlayed
                    ? `tw-h-[40px] tw-absolute tw-top-[3%] ${isMobile ? "tw-left-[85%]" : "tw-left-[67%]"}`
                    : "tw-relative"
            )}
            style={{ zIndex }}
        >
            {renderMapContent()}
        </div>
    );
};

LeafletMap.propTypes = {
    property: PropTypes.shape({
        street_address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip_code: PropTypes.string,
    }).isRequired,
    mapOverlayed: PropTypes.bool,
};

LeafletMap.defaultProps = {
    mapOverlayed: false,
};

export default LeafletMap;
