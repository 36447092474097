import React from "react";
import "./credits.scss";

const CreditsPage = () => {
    return (
        <div className="container credits pb-4">
            <h1 className="px-0 pt-4 app-text-title text-center">Credits for Assets</h1>

            <div className="d-flex flex-column align-items-center gap-4">
                <a
                    href="https://www.flaticon.com/free-icons/address"
                    title="address icons"
                    className="align-self-center app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Address icons created by Freepik - Flaticon
                </a>

                <a
                    href="https://www.flaticon.com/free-icons/sold-out"
                    title="sold out icons"
                    className="align-self-center app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Sold out icons created by Freepik - Flaticon
                </a>

                <a
                    href="https://www.flaticon.com/free-icons/head-hit"
                    title="head hit icons"
                    className="align-self-center app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Head hit icons created by Md Tanvirul Haque - Flaticon
                </a>
                <a
                    href="https://www.flaticon.com/free-icons/together"
                    title="together icons"
                    className="align-self-center app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Together icons created by Freepik - Flaticon
                </a>
                <a
                    href="https://www.flaticon.com/free-icons/open"
                    title="open icons"
                    className="align-self-center app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Open icons created by Freepik - Flaticon
                </a>
                <a
                    href="https://www.flaticon.com/free-icons/question-mark"
                    title="question mark icons"
                    className="align-self-center app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Question mark icons created by Freepik - Flaticon
                </a>
                <a
                    href="https://www.flaticon.com/free-icons/compare"
                    title="compare icons"
                    className="align-self-center app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Compare icons created by kerismaker - Flaticon
                </a>
                <a
                    href="https://www.flaticon.com/free-icons/open-menu"
                    title="open menu icons"
                    className="align-self-center app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Open menu icons created by bqlqn - Flaticon
                </a>
                <a
                    href="https://www.flaticon.com/free-icons/calendar"
                    title="calendar icons"
                    className="align-self-center app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Calendar icons created by Freepik - Flaticon
                </a>
                <a
                    href="https://www.flaticon.com/free-icons/give-up"
                    title="Give up icons"
                    className="align-self-center app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Give up icons created by Umeicon - Flaticon
                </a>
            </div>
        </div>
    );
};

export default CreditsPage;
